import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { down } from "styled-breakpoints"
//STYLED
import { H2, Text, LabelBW } from "../../utils/typo"
//COMPONENTS
import { ContainerMob } from "../../utils/utils"

const Wrapper = styled(ContainerMob)`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 4rem;
  overflow: hidden;
  align-items: center;
  margin-bottom: 3rem;

  ${down("sm")} {
    grid-template-columns: 1fr;
  }
`

const Imagewrapper = styled.div`
  ${down("sm")} {
    display: none;
  }
`

const Component = styled.div`
  background-color: ${props => props.bgcolor || "transparent"};
  position: relative;
  padding-top: 6rem;
  padding-bottom: 3rem;
`

class SectionIks extends React.Component {
  render() {
    return (
      <Component
        as="section"
        bgcolor={this.props.bgcolor}
        id={this.props.id || 1}
      >
        <Wrapper>
          <div>
            <LabelBW mb="2rem">{this.props.label || "Label"}</LabelBW>
            <H2 mb="1rem" lineHeight="1.1">
              {this.props.title || "title"}
            </H2>
            <Text mt="2rem" weight="900">
              — {this.props.intro}
            </Text>
            <Text mt="2rem">{this.props.text || "tekst"}</Text>
          </div>
          <Imagewrapper>
            <GatsbyImage
              image={this.props.image}
              alt={this.props.alt}
              objectFit="contain"
            />
          </Imagewrapper>
        </Wrapper>

        {this.props.children}
      </Component>
    )
  }
}
export default SectionIks
