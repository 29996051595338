import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { down } from "styled-breakpoints"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
//DATA
import {
  drukM,
  drukW,
  drukFoto,
  papier,
  klejenieT,
  klejenieP,
  laminowanie,
  oprawa,
  inne,
} from "../assets/data/ceny"
//STYLES
import { H4, H2, LabelBW, Text } from "../utils/typo"
import { theme } from "../utils/theme"
import {
  ContainerMob,
  CardWrapperMob,
  Container,
  Section,
  Pattern,
} from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/SEO"
import Layout from "../layout/layout"
import Button from "../components/button/Button"
import AccordionCardCennik from "../components/accordionCard/AccordionIksCennik1"
import SectionIks from "../components/section/SectionIks"

const CennikWrapper = styled.div`
  grid-column: span 2;
  padding-bottom: 2rem;
  padding-right: 2rem;
  ${down("xs")} {
    grid-column: span 1;
  }
`

const Card = styled(Link)`
  height: 100%;
  background-color: ${props => props.bgcolor || props.theme.color.grey9};
  outline: 1px solid lightgrey;
  outline-offset: -0.5px;
  padding: 2rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h4 {
    line-height: 1;
    margin-bottom: 1rem;
  }

  &:hover {
    background-color: ${props => props.theme.color.base1};
  }
`

const ImgCard = styled.div`
  ${down("sm")} {
    display: none;
  }
`

const Lines = styled(Pattern)`
  height: ${props => props.height || "auto"};
  position: relative;
  padding-top: 500px;
  margin-top: -500px;
  z-index: -1;
`

const CennikTable = styled.table`
  width: 100%;
  margin-top: ${props => props.mt};
`

const Cennik = ({
  data: {
    ceny: {
      frontmatter: { title, intro, heading, cenyKategorie },
    },
  },
}) => {
  return (
    <div>
      <Seo title="Cennik" slug="/cennik" />
      <Layout>
        {/* WSTĘP DO CENNIKA */}
        <Section pt="6rem" pb="3rem">
          <CardWrapperMob l="4">
            <CennikWrapper>
              <LabelBW>{title}</LabelBW>
              <H2 as="h1" lineHeight="1.2">
                {heading}
              </H2>
              <Text mt="2rem">{intro}</Text>
            </CennikWrapper>
            {cenyKategorie.map(kategoria => (
              <Card
                key={kategoria.id}
                bgcolor={
                  (kategoria.id === 1) | (kategoria.id === 3)
                    ? theme.color.grey9
                    : (kategoria.id === 2) | (kategoria.id === 4)
                    ? theme.color.base3
                    : (kategoria.id === 5) | (kategoria.id === 7)
                    ? theme.color.grey5
                    : theme.color.base1
                }
                to={"/cennik#" + kategoria.id}
              >
                <div>
                  <H4>{kategoria.title}</H4>
                  <Text>{kategoria.label}</Text>
                </div>

                <Button to={"/cennik#" + kategoria.id}>Zobacz ceny</Button>
              </Card>
            ))}
            <ImgCard>
              <StaticImage
                placeholder="blurred"
                alt="W drukarni IKS Druk"
                src="../assets/images/index2.jpg"
                layout="fullWidth"
                objectFit="contain"
                aspectRatio="1"
              />
            </ImgCard>
          </CardWrapperMob>
        </Section>
        <Lines />

        {/* DRUK MAŁOFORMATOWY */}
        <SectionIks
          bgcolor={theme.color.white}
          id={cenyKategorie[0].id}
          label={cenyKategorie[0].label}
          title={cenyKategorie[0].title}
          intro={cenyKategorie[0].intro}
          image={cenyKategorie[0].file.childImageSharp.gatsbyImageData}
          alt={cenyKategorie[0].alt}
          text={cenyKategorie[0].text}
        >
          <ContainerMob>
            <CennikTable>
              {drukM.map(cena => (
                <AccordionCardCennik
                  id={cena.id}
                  key={cena.id}
                  col1={cena.format}
                  col2={cena.grupa_1_20}
                  col3={cena.grupa_21_50}
                  col4={cena.grupa_51_100}
                  col5={cena.grupa_101_300}
                  col6={cena.grupa_300}
                  col2label={cena.label}
                  col3label={cena.label}
                  col4label={cena.label}
                  col5label={cena.label}
                  col6label={cena.label}
                ></AccordionCardCennik>
              ))}
            </CennikTable>
          </ContainerMob>
        </SectionIks>

        {/* PAPIERY A$ i A3 */}
        <SectionIks
          bgcolor={theme.color.grey9}
          id={cenyKategorie[1].id}
          label={cenyKategorie[1].label}
          intro={cenyKategorie[1].intro}
          title={cenyKategorie[1].title}
          image={cenyKategorie[1].file.childImageSharp.gatsbyImageData}
          alt={cenyKategorie[1].alt}
          text={cenyKategorie[1].text}
        >
          <Container>
            <CennikTable>
              {papier.map(cena => (
                <AccordionCardCennik
                  id={cena.id}
                  key={cena.id}
                  col1={cena.papier}
                  col1label={cena.papierGramatura}
                  text={cena.papierOpis}
                  rozwijanie={cena.rozwijanie}
                  col2={cena.a4}
                  col3={cena.a3}
                  col4={cena.sra3}
                  col2label={cena.a4label}
                  col3label={cena.a3label}
                  col4label={cena.sra3label}
                ></AccordionCardCennik>
              ))}
            </CennikTable>
          </Container>
        </SectionIks>

        {/* CAD */}
        <SectionIks
          bgcolor={theme.color.white}
          id={cenyKategorie[2].id}
          label={cenyKategorie[2].label}
          intro={cenyKategorie[2].intro}
          title={cenyKategorie[2].title}
          image={cenyKategorie[2].file.childImageSharp.gatsbyImageData}
          alt={cenyKategorie[2].alt}
          text={cenyKategorie[2].text}
        >
          <ContainerMob>
            <CennikTable>
              {drukW.map(cena => (
                <AccordionCardCennik
                  id={cena.id}
                  key={cena.id}
                  col1={cena.zadruk}
                  col2={cena.a2}
                  col3={cena.a1}
                  col4={cena.a0}
                  col5={cena.b2}
                  col6={cena.b1}
                  col2label={cena.a2label}
                  col3label={cena.a1label}
                  col4label={cena.a0label}
                  col5label={cena.b2label}
                  col6label={cena.b1label}
                ></AccordionCardCennik>
              ))}
            </CennikTable>
          </ContainerMob>
        </SectionIks>

        {/* WIELKOFORMATOWY */}
        <SectionIks
          bgcolor={theme.color.grey9}
          id={cenyKategorie[3].id}
          label={cenyKategorie[3].label}
          intro={cenyKategorie[3].intro}
          title={cenyKategorie[3].title}
          image={cenyKategorie[3].file.childImageSharp.gatsbyImageData}
          alt={cenyKategorie[3].alt}
          text={cenyKategorie[3].text}
        >
          <Container>
            <CennikTable>
              {drukFoto.map(cena => (
                <AccordionCardCennik
                  id={cena.id}
                  key={cena.id}
                  col1={cena.papier}
                  col1label={cena.papierGramatura}
                  text={cena.papierOpis}
                  rozwijanie={cena.rozwijanie}
                  col2={
                    typeof cena.a2 == "string"
                      ? cena.a2
                      : `${cena.a2} — ${cena.a2 + 2} — ${
                          cena.a2 + 4
                        } zł`.replace(".", ",")
                  }
                  col3={
                    typeof cena.a1 == "string"
                      ? cena.a1
                      : `${cena.a1} — ${cena.a1 + 3} — ${
                          cena.a1 + 6
                        } zł`.replace(".", ",")
                  }
                  col4={
                    typeof cena.a0 == "string"
                      ? cena.a0
                      : `${cena.a0} — ${cena.a0 + 5} — ${
                          cena.a0 + 10
                        } zł`.replace(".", ",")
                  }
                  col5={
                    typeof cena.b2 == "string"
                      ? cena.b2
                      : `${cena.b2} — ${cena.b2 + 2} — ${
                          cena.b2 + 4
                        } zł`.replace(".", ",")
                  }
                  col6={
                    typeof cena.b1 == "string"
                      ? cena.b1
                      : `${cena.b1} — ${cena.b1 + 4} — ${
                          cena.b1 + 8
                        } zł`.replace(".", ",")
                  }
                  col2label={cena.a2label}
                  col3label={cena.a1label}
                  col4label={cena.a0label}
                  col5label={cena.b2label}
                  col6label={cena.b1label}
                ></AccordionCardCennik>
              ))}
            </CennikTable>
          </Container>
        </SectionIks>

        {/* PODKLEJENIE TEKTURA */}
        <SectionIks
          bgcolor={theme.color.white}
          id={cenyKategorie[4].id}
          label={cenyKategorie[4].label}
          intro={cenyKategorie[4].intro}
          title={cenyKategorie[4].title}
          image={cenyKategorie[4].file.childImageSharp.gatsbyImageData}
          alt={cenyKategorie[4].alt}
          text={cenyKategorie[4].text}
        >
          <Container>
            <CennikTable>
              {klejenieT.map(cena => (
                <AccordionCardCennik
                  id={cena.id}
                  key={cena.id}
                  col1={cena.podklad}
                  col1label={cena.wykonczenie}
                  col2={cena.t2}
                  col3={cena.t25}
                  col4={cena.t3}
                  col2label={cena.t2label}
                  col3label={cena.t25label}
                  col4label={cena.t3label}
                ></AccordionCardCennik>
              ))}
            </CennikTable>
          </Container>
        </SectionIks>

        {/* PODKLEJENIE PIANKA */}
        <SectionIks
          bgcolor={theme.color.white}
          id={cenyKategorie[5].id}
          label={cenyKategorie[5].label}
          intro={cenyKategorie[5].intro}
          title={cenyKategorie[5].title}
          image={cenyKategorie[5].file.childImageSharp.gatsbyImageData}
          alt={cenyKategorie[5].alt}
          text={cenyKategorie[5].text}
        >
          <Container>
            <CennikTable>
              {klejenieP.map(cena => (
                <AccordionCardCennik
                  id={cena.id}
                  key={cena.id}
                  col1={cena.podklad}
                  col1label={cena.wykonczenie}
                  col2={cena.p3}
                  col3={cena.p5}
                  col4={cena.pcw}
                  col2label={cena.p3label}
                  col3label={cena.p5label}
                  col4label={cena.pcwlabel}
                ></AccordionCardCennik>
              ))}
            </CennikTable>
          </Container>
        </SectionIks>

        {/* LAMINOWANIE */}
        <SectionIks
          bgcolor={theme.color.grey9}
          id={cenyKategorie[6].id}
          label={cenyKategorie[6].label}
          intro={cenyKategorie[6].intro}
          title={cenyKategorie[6].title}
          image={cenyKategorie[6].file.childImageSharp.gatsbyImageData}
          alt={cenyKategorie[6].alt}
          text={cenyKategorie[6].text}
        >
          <Container>
            <CennikTable>
              {laminowanie.map(cena => (
                <AccordionCardCennik
                  id={cena.id}
                  key={cena.id}
                  col1={cena.podklad}
                  col1label={cena.wykonczenie}
                  col2={cena.a4}
                  col3={cena.a3}
                  col4={cena.b2}
                  col5={cena.b1}
                ></AccordionCardCennik>
              ))}
            </CennikTable>
          </Container>
        </SectionIks>

        {/* USŁUGI */}
        <SectionIks
          bgcolor={theme.color.white}
          id={cenyKategorie[7].id}
          label={cenyKategorie[7].label}
          intro={cenyKategorie[7].intro}
          title={cenyKategorie[7].title}
          image={cenyKategorie[7].file.childImageSharp.gatsbyImageData}
          alt={cenyKategorie[7].alt}
          text={cenyKategorie[7].text}
        >
          <Container>
            <CennikTable>
              {oprawa.map(cena => (
                <AccordionCardCennik
                  key={cena.id}
                  id={cena.id}
                  col1={cena.oprawa}
                  col1label={cena.wykonczenie}
                  col2={cena.rozmiary}
                  col3={cena.a4}
                  col4={cena.a3}
                  col3label={cena.a4label}
                  col4label={cena.a3label}
                ></AccordionCardCennik>
              ))}
            </CennikTable>

            <CennikTable mt="5rem">
              {inne.map(cena => (
                <AccordionCardCennik
                  key={cena.id}
                  id={cena.id}
                  col1={cena.usluga}
                  col1label={cena.uslugalabel}
                  col2={cena.cenauslugi}
                  col2label={cena.cenauslugilabel}
                ></AccordionCardCennik>
              ))}
            </CennikTable>
          </Container>
        </SectionIks>
      </Layout>
    </div>
  )
}
export default Cennik

export const query = graphql`
  query {
    ceny: mdx(slug: { eq: "cennik/ceny" }) {
      frontmatter {
        title
        heading
        intro
        cenyKategorie {
          id
          title
          intro
          label
          text
          alt
          file {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`
